.failed {
  display: flex;
  flex-direction: column;
  align-items: center;
  .failed__result {
    padding-bottom: 0;
  }
  .failed__notice1 {
    color: #0e2c0e;
    font-family: Montserrat-Regular;
    font-size: 16px;
  }
  .failed__notice2 {
    font-family: Montserrat-Regular;
    max-width: 500px;
    line-height: 1.7;
    font-size: 12px;
    color: #878787;
    text-align: center;
  }
}

@media only screen and (max-width: 800px) {
  .failed {
    background-color: #fff;
    padding: 20px 0;
    .failed__notice1 {
      margin: 10px 0;
    }
    .failed__notice2 {
      margin: 10px 12px;
    }
  }
}
