.authorizationFail {
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Montserrat-Regular;
  font-size: 14px;
  color: #878787;
  text-align: center;
  button {
    background-color: var(--luxtak--color);
    border: none;
    border-radius: 4px;
    padding: 10px;
    margin: 10px 0;
    color: #ffffff;
    font-family: Montserrat-Regular;
    font-size: 13px;
    cursor: pointer;
  }
}
