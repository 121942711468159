.kushkiOtp {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(188, 189, 189, 0.34);
  .kushkiOtp__window {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #dee8f2;
    padding: 50px;
    .kushkiOtp__otpNotice {
      color: #0e2c0e;
      text-align: center;
      .kushkiOtp__otpNotice__note {
        font-family: Montserrat-Regular;
        font-size: 14px;
        padding: 3px;
        border-radius: 8px;
        background-color: rgb(195, 228, 254);
      }
      .kushkiOtp__otpNotice__title {
        font-family: Montserrat-Bold;
        font-size: 18px;
        margin: 0.5rem 0;
      }
      .kushkiOtp__otpNotice__content {
        display: inline-block;
        font-family: Montserrat-Regular;
        font-size: 14px;
        margin: 1rem 8px;
      }
      .kushkiOtp__otpNotice__payment {
        margin-bottom: 20px;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: Montserrat-Regular;
        font-size: 14px;
        border: #dee8f2 1px solid;
        border-radius: 6px;
        span {
          border: #dee8f2 1px solid;
          border-radius: 6px;
          padding: 5px;
        }
      }
    }
    .kushkiOtp__container {
      justify-content: center;
      margin: 10px 0;
      .kushkiOtp__input {
        width: 3rem !important;
        height: 3rem;
        margin: 0 0.5rem;
        font-size: 2rem;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .kushkiOtp {
    .kushkiOtp__window {
      box-sizing: border-box;
      width: 90%;
      top: 50%;
      padding: 20px;
      .kushkiOtp__container {
        justify-content: center;
        .kushkiOtp__input {
          width: 2rem !important;
          height: 2rem;
          margin: 0 0.2rem;
          font-size: 1rem;
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}
