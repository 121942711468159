.paygoOtp {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(188, 189, 189, 0.34);
  .paygoOtp__window {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #dee8f2;
    padding: 50px;
    .paygoOtp__otpNotice {
      color: #0e2c0e;
      text-align: center;
      p:nth-child(1) {
        font-family: Montserrat-Bold;
        font-size: 18px;
        margin: 0.5rem 0;
      }
      p:nth-child(2) {
        display: inline-block;
        font-family: Montserrat-Regular;
        font-size: 14px;
        margin: 1rem 8px;
      }
    }
    .paygoOtp__container {
      justify-content: center;
      margin: 10px 0;
      .paygoOtp__input {
        width: 3rem !important;
        height: 3rem;
        margin: 0 0.5rem;
        font-size: 2rem;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .paygoOtp {
    .paygoOtp__window {
      box-sizing: border-box;
      width: 90%;
      top: 50%;
      padding: 20px;
      .paygoOtp__container {
        justify-content: center;
        .paygoOtp__input {
          width: 2rem !important;
          height: 2rem;
          margin: 0 0.2rem;
          font-size: 1rem;
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}
