.checkoutSafetyPayMobile {
  display: none;
}
.checkoutSafetyPay {
  .checkout--instructionTitle {
    font-family: Montserrat-Bold;
    font-size: 14px;
    color: #0e2c0e;
    margin-top: 0;
  }

  .checkout--instructionsHeader {
    font-family: Montserrat-Regular;
    font-size: 13px;
    color: #0e2c0e;
  }

  .checkout--instructions {
    p {
      margin-top: 7px;
      margin-bottom: 7px;
      font-family: Montserrat-Regular;
      font-size: 12px;
      color: #0e2c0e;
      line-height: 1.6;
    }
    .checkout--instructions__title {
      font-family: Montserrat-Bold;
      font-size: 13px;
      color: #0e2c0e;
    }
  }
  .checkoutSafetyPayPc {
    .checkoutSafetyPayPc__instBottom {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      > * {
        margin: 20px;
      }
      .checkoutSafetyPayPc__instBottom__left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        background-color: #f1f4f8;
        > * {
          margin: 5px;
        }
        span {
          font-family: DMSans-Bold;
          font-size: 16px;
          color: #3a87ef;
          text-align: center;
        }
        img {
          width: 50px;
        }
      }
      .ccheckoutSafetyPayPc__instBottom__direction {
        width: 40px;
      }
      .checkoutSafetyPayPc__instBottom__channels {
        .checkoutSafetyPayPc__instBottom__channelLogo {
          width: 90px;
          margin: 2px;
        }
        .checkoutSafetyPayPc__instBottom__channelText {
          border: solid 1px rgb(209, 209, 209);
          box-sizing: border-box;
          min-width: 80px;
          margin: 2px;
          font-family: Montserrat-Regular;
          font-size: 13px;
          text-align: center;
          padding: 12px 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .checkoutSafetyPayPc {
    display: none;
  }
  .checkoutSafetyPayMobile {
    display: block;
    line-height: 1.6;
    margin-top: 10px;
    .checkoutSafetyPayMobile__instructions__bottom {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      justify-content: center;
      align-items: center;
      .checkoutSafetyPayMobile__instructions__bottomInsContainer {
        display: flex;
        background-color: #ffffff;
        justify-content: space-around;
        align-items: center;
        border-radius: 10px;
        padding: 10px 20px;
        span {
          font-family: DMSans-Bold;
          font-size: 13px;
          color: #3a87ef;
          margin: 0 5px;
          text-align: center;
        }
        img {
          height: 40px;
        }
      }
      .checkoutSafetyPayMobile__instructions__bottomDirectionDown {
        height: 30px;
        width: 30px;
        margin: 20px auto;
      }
      .checkoutSafetyPayMobile__instructions__channelLogo {
        width: 180px;
      }
      .checkoutSafetyPayMobile__instructions__bottom__channels {
        text-align: center;
        .checkoutSafetyPayMobile__instructions__bottom__channelLogo {
          width: 60px;
          margin: 2px;
        }
        .checkoutSafetyPayMobile__instructions__bottom__channelText {
          border: solid 1px rgb(209, 209, 209);
          box-sizing: border-box;
          min-width: 80px;
          margin: 2px;
          font-family: Montserrat-Regular;
          font-size: 13px;
          text-align: center;
          padding: 12px 5px;
        }
      }
    }
    .checkoutSafetyPayMobile__return {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      a {
        color: var(--luxtak--color);
      }
    }
  }
}
