.details {
  .details__channelContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    img {
      height: 60px;
      width: 80px;
      object-fit: contain;
    }
    .details__channelName {
      font-family: Montserrat-Bold;
      font-size: 13px;
      color: #0e2c0e;
    }
    .details__channelImage {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      background-color: #ffffff;
      object-fit: contain;
    }
  }
  .details__floatRight {
    display: flex;
    justify-content: flex-end;
  }
  .details__text {
    font-family: Montserrat-Regular;
    font-size: 13px;
    color: #878787;
    margin: 10px 0;
  }
  .details__value {
    font-family: Montserrat-Regular;
    font-size: 13px;
    color: #0e2c0e;
    margin: 10px 0;
  }
  .details__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .details__hide {
    font-family: Montserrat-Regular;
    font-size: 13px;
    color: #1e86ff;
    cursor: pointer;
  }
}
@media only screen and (max-width: 800px) {
  .details {
    .details__channelContainer {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 10px 0;
      margin-bottom: 5px;
      align-items: center;
      .details__channelImage {
        height: auto;
        background-color: inherit;
        img {
          width: 60px;
          height: 30px;
          object-fit: scale-down;
          margin-left: 5px;
          padding: 5px;
          // border: 1px dashed rgba(0, 0, 0, 0.23);
        }
      }
      .details__channelName {
        font-size: 18px;
      }
    }
  }
}
