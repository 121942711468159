.mainSkeletonMobile {
  display: none;
}
.mainSkeleton {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 1200px;
  padding: 20px;
  .mainSkeleton__left {
    flex-grow: 1;
    padding-right: 50px;
    .mainSkeleton__left__methods {
      display: flex;
      padding: 50px 0;
    }
    .mainSkeleton__left__form {
      display: flex;
      flex-direction: column;
      padding: 40px 0;
      .mainSkeleton__left__form__row {
        display: flex;
      }
    }
  }
  .mainSkeleton__right {
    width: 380px;
  }
}

@media only screen and (max-width: 800px) {
  .mainSkeleton {
    display: none;
  }
  .mainSkeletonMobile {
    display: block;
    padding: 70px 10px 0;
  }
}
