.countryDropDown {
  .countryDropDown__countryFlag {
    width: 34px;
    height: 22px;
    object-fit: contain;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
  }
}
.languageDropdown {
  .languageDropdown__langContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    .languageDropdown__langText {
      margin-left: 5px;
      text-transform: none;
      font-family: Montserrat-Medium;
    }
  }
}
.countryDropDown,
.languageDropdown {
  .arrow__0 {
    transform: rotate(0deg);
    transition: transform 0.2s linear;
    filter: grayscale(100%);
  }
  .arrow__180 {
    transform: rotate(180deg);
    transition: transform 0.2s linear;
    filter: grayscale(100%);
  }
}

.headerMobile {
  display: none;
}
.header {
  width: 100%;
  height: 73px;
  display: flex;
  justify-content: center;
  box-shadow: 0 8px 35px -25px rgba(0, 0, 0, 0.35);

  .header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 1200px;
    .header__container__text {
      font-size: 34px;
      font-family: Montserrat-Medium;
      margin-left: 5px;
      background: -webkit-linear-gradient(#fc6b5d, #fcb28f);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .header__luxtak {
      padding-bottom: 6px;
      height: 40px;
    }

    .header__rightContainer {
      display: flex;
      align-items: center;
    }
    .header__info {
      margin: 0 45px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 800px) {
  .countryDropDown {
    .MuiButton-text,
    .MuiButton-root {
      padding-left: 0;
      min-width: 75px;
    }
    .MuiButton-label {
      justify-content: flex-start;
    }
  }
  .header {
    display: none;
  }
  .headerMobile {
    position: fixed;
    z-index: 500;
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 12.5px 15px;
    background-color: var(--luxtak--color);
    .headerMobile__top {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .headerMobile__returnButton {
        position: absolute;
        left: 0;
        top: 5px;
        padding: 5px;
      }
      .headerMobile__luxtak__logo {
        height: 30px;
      }
      .headerMobile__luxtak {
        font-size: 30px;
        font-family: Montserrat-Medium;
        margin-left: 5px;
        background: -webkit-linear-gradient(#fff, #fcb28f);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .headerMobile__bottom {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .headerMobile__infoContainer {
        display: flex;
        justify-content: flex-end;
        min-width: 75px;
        .headerMobile__info {
          cursor: pointer;
        }
      }
    }
  }
  .header--langContainer {
    .header__langTextSelect {
      color: #ffffff;
      margin-right: 5px;
    }
  }
  .header--langContainer:hover .header__langDropDown {
    left: 0;
    right: auto;
  }
}

@media only screen and (max-height: 500px) {
  .headerMobile {
    .headerMobile__top {
      display: none;
    }
  }
}
