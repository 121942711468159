.tooltip {
  position: relative;
  display: flex;
  .tooltip__content {
    display: flex;
    text-transform: none;
    font-family: Montserrat-Medium;
    box-sizing: border-box;
    font-size: 13px;
    visibility: hidden;
    width: 200px;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
    color: white;
    background-color: #f1a00b;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 100;
    top: calc(100% + 20px);
    left: 50%;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    opacity: 0;
  }
  .tooltip__arrow {
    position: absolute;
    visibility: hidden;
    top: calc(100% - 20px);
    left: 50%;
    margin-left: -15px;
    border-width: 20px 15px;
    border-style: solid;
    border-color: transparent transparent #f1a00b transparent;
    z-index: 100;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    opacity: 0;
  }
  .bottom-left {
    left: unset;
    right: calc(50% - 20px);
    top: calc(100% + 21px);
    margin-left: 0;
  }
  .bottom-right {
    transform: translate(-30%, 0) !important;
  }
}

.tooltip:hover .tooltip__content {
  visibility: visible;
  opacity: 1;
  transform: none;
}
.tooltip:hover .tooltip__arrow {
  visibility: visible;
  opacity: 1;
  transform: none;
}
