.barcodeAndPDF {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  .barcodeAndPDF__content__image {
    width: 100%;
    margin-top: 20px;
  }
  .barcodeAndPDF__barcode {
    display: flex;
    flex-direction: column;
    align-items: center;
    > * {
      margin-bottom: 20px;
      margin: auto;
    }
  }
}

@media only screen and (max-width: 800px) {
  .checkoutBoleto {
    .checkoutBoleto__content {
      justify-content: center;
    }
  }
}
