.method {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  width: 120px;
  cursor: pointer;
  border: solid 1px white;
  margin-bottom: 20px;
  .method__logoContainer {
    position: relative;
    .method__logo {
      height: 52px;
      width: 52px;
      object-fit: scale-down;
      background-color: #fdeee6;
      border-radius: 52px;
    }
    .method__selected {
      position: absolute;
      top: -8px;
      right: -8px;
      z-index: 5;
    }
    .method__promotion {
      position: absolute;
      top: -25px;
      left: 35%;
      transform: translate(-50%, 0);
    }
  }
  .method__text {
    font-family: Montserrat-Regular;
    font-size: 13px;
    text-align: center;
    color: #0e2c0e;
    margin: 10px 5px;
  }
}

.method--border {
  border: solid 1px var(--luxtak--color);
  border-radius: 4px;
}
.method--filter {
  filter: grayscale(100%);
  background-color: #f0f0f0;
}
