.authorizationMobile {
  display: none;
}
.authorization {
  display: flex;
  justify-content: center;
  .authorization__container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    margin: 30px;
    .authorization__left {
      box-sizing: border-box;
      line-height: 1.8;
      width: 100%;
      border: 2px solid #f1f4f8;
      margin-right: 50px;
      .authorization__left__title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 0 10px;
        font-family: Montserrat-Bold;
        font-size: 18px;
        color: #0e2c0e;
        background-color: #eefaea;
      }
      .authorization__left__radioGroup {
        margin: 20px 30px;
        .MuiFormControlLabel-label {
          font-family: Montserrat-Medium;
        }
        .authorizationMobile__content__radioButton {
          border: 1px dashed gray;
          border-radius: 4px;
          background-color: #eefaea;
          margin-right: 0;
        }
      }
      .authorization__left__confirmButton {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        button {
          background-color: var(--luxtak--color);
          border: none;
          border-radius: 4px;
          height: 50px;
          width: 150px;
          margin: 10px 0;
          color: #ffffff;
          font-family: Montserrat-Regular;
          font-size: 18px;
          cursor: pointer;
        }
      }
    }
  }
}

.authorization__otp {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(188, 189, 189, 0.34);
  .authorization__otpWindow {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #dee8f2;
    padding: 50px;
    .authorization__otpNotice {
      color: #0e2c0e;
      text-align: center;
      p:nth-child(1) {
        font-family: Montserrat-Bold;
        font-size: 18px;
        margin: 0.5rem 0;
      }
      p:nth-child(2) {
        display: inline-block;
        font-family: Montserrat-Regular;
        font-size: 14px;
        margin: 1rem 8px;
      }
    }
    .authorization__otpNoticeBottom {
      color: #0e2c0e;
      font-family: Montserrat-Regular;
      font-size: 14px;
      margin: 2rem 8px 0;
      text-align: center;
      .resent--active {
        color: #3a87ef;
        cursor: pointer;
      }
    }
    .authorization__otp__container {
      justify-content: space-between;
      margin: 10px 0;
      .authorization__otp__input {
        width: 3rem !important;
        height: 3rem;
        margin: 0 0.5rem;
        font-size: 2rem;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .authorization {
    display: none;
  }
  .authorizationMobile {
    display: block;
    padding: 100px 20px 75px 20px;
    background-color: #f2f3f7;
    box-sizing: border-box;
    min-height: 100vh;
    .authorizationMobile__top {
      .authorizationMobile--floatRight {
        display: flex;
        justify-content: flex-end;
      }
      p {
        margin: 5px 0;
      }
      .authorizationMobile__top__totalText {
        font-family: Montserrat-Regular;
        font-size: 15px;
        color: #0c3666;
      }
      .authorizationMobile__top__totalValue {
        font-family: Montserrat-Regular;
        color: #000000;
        font-size: 20px;
        margin-left: 5px;
      }
    }
    .authorizationMobile__content {
      background-color: #ffffff;
      padding-bottom: 1px;
      .authorizationMobile__content__title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 0 10px;
        font-family: Montserrat-Bold;
        font-size: 18px;
        color: #0e2c0e;
        background-color: #eefaea;
        p {
          text-align: center;
        }
      }
      .authorizationMobile__content__radioGroup {
        padding: 20px 10px;
        .MuiFormControlLabel-label {
          font-family: Montserrat-Medium;
        }
        .authorizationMobile__content__radioButton {
          border: 1px dashed gray;
          border-radius: 4px;
          background-color: #eefaea;
          margin: 0;
        }
      }
    }
    .authorizationMobile__footer {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      padding: 0 20px;
      background-color: #ffffff;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
      z-index: 100;
      .authorizationMobile__footer__confirmButton {
        background-color: var(--luxtak--color);
        border: none;
        border-radius: 4px;
        height: 50px;
        margin: 10px 0;
        color: #ffffff;
        font-family: Montserrat-Regular;
        font-size: 18px;
      }
    }
  }
  .authorization__otp {
    .authorization__otpWindow {
      box-sizing: border-box;
      width: 90%;
      top: 50%;
      padding: 20px;
      .authorization__otp__container {
        justify-content: center;
        .authorization__otp__input {
          width: 2rem !important;
          height: 2rem;
          margin: 0 0.2rem;
          font-size: 1rem;
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}
