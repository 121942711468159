.dropZoneCustom {
  border: 1px dashed rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  .dzu-dropzone {
    flex-direction: row;
    overflow: visible;
    border: none;
    min-height: auto;
    .dropZoneCustom__label {
      display: flex;
      align-items: center;
      font-family: Montserrat-Regular;
      color: #0e2c0e;
      font-size: 13px;
      padding: 20px 20px;
      cursor: pointer;
      width: 100%;
      .dropZoneCustom__label__text {
        p {
          margin: 5px 10px;
          line-height: 1.3;
        }
        .dropZoneCustom__label__textDragDrop {
          font-size: 11px;
        }
      }
    }
  }
  .dzu-previewContainer {
    min-height: auto;
    padding: 20px;
    padding-top: 0;
    border-bottom: none;
    span {
      font-family: Montserrat-Regular;
      font-size: 10px;
    }
    .dzu-previewImage {
      max-width: 200px;
    }
  }
}

.dropZonePreview {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(188, 189, 189, 0.2);
  .dropZonePreview__container {
    position: absolute;
    background-color: #ffffff;
    top: 10%;
    left: 10%;
    .dropZonePreview__button {
      text-align: center;
      button {
        background-color: var(--luxtak--color);
        border: none;
        border-radius: 4px;
        height: 40px;
        margin: 10px;
        color: #ffffff;
        font-family: Montserrat-Regular;
        font-size: 18px;
        cursor: pointer;
        min-width: 150px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .dropZoneCustom {
    .dropZoneCustom__label__text .dropZoneCustom__label__textDragDrop {
      display: none;
    }
  }
}
