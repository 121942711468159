.checkoutSeven11 {
  padding-right: 50px;
  .checkoutSeven11__inst {
    background-color: #eefaea;
    color: #0e2c0e;
    padding: 5px 20px;
    .checkoutSeven11__instTitle {
      font-family: Montserrat-Bold;
      font-size: 15px;
      margin: 0.5rem 0;
    }
    .checkoutSeven11__instContent {
      font-family: Montserrat-Regular;
      font-size: 14px;
      margin: 0.5rem 0;
    }
  }
  .checkoutSeven11__code {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Montserrat-Regular;
    font-size: 14px;
    padding: 10px 0;
    p {
      margin: 0.5rem 0;
      span {
        font-family: Montserrat-Bold;
        color: #3341b1;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .checkoutSeven11 {
    padding-right: 0;
    background-color: #ffffff;
    .checkoutSeven11__inst {
      .checkoutSeven11__instTitle {
        font-size: 14px;
        text-align: center;
      }
      .checkoutSeven11__instContent {
        font-size: 13px;
      }
    }
  }
}
