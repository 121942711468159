.checkoutSpei {
  margin-right: 50px;
  .checkoutSpei__instContainer {
    background-color: #eefaea;
    padding: 15px;
    font-size: 15px;
    font-family: Montserrat-Medium;
    .checkoutSpei__instContainer--bold {
      color: var(--luxtak--color);
      font-family: Montserrat-Bold;
    }
  }
  .checkoutSpei__account {
    margin-top: 30px;
    font-size: 15px;
    font-family: Montserrat-Bold;
    color: #0e2c0e;
    .checkoutSpei__account__key {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .checkoutSpei__row {
      display: flex;
      p {
        margin: 10px 0;
      }
      .checkoutSpei__title {
        font-size: 13px;
        width: 40%;
      }
      .checkoutSpei__value {
        width: 60%;
        font-family: Montserrat-Medium;
        color: #727272;
      }
      .checkoutSpei__account__clabe {
        width: 60%;
        display: flex;
        align-items: center;
        .checkoutSpei__clabe {
          margin-right: 40px;
        }
      }
    }
    hr {
      border-width: 0;
      height: 1px;
      background-color: rgba(145, 145, 147, 0.2);
      width: 100%;
      margin: 5px 0;
    }
  }
  .checkoutSpei__openpay {
    .checkoutSpei__openpay__inst {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .checkoutSpei__openpay__instContent {
        box-sizing: border-box;
        width: 48%;
        background-color: #eefaea;
        padding: 10px 20px;
        .checkoutSpei__openpay__instTitle {
          font-family: Montserrat-Regular;
          font-size: 14px;
        }
        .checkoutSpei__openpay__instValue {
          font-family: Montserrat-Medium;
          font-size: 14px;
        }
        .checkoutSpei__openpay__instRow {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-end;
          .checkoutSpei__openpay__instColumn {
            display: flex;
            flex-direction: column;
            p {
              margin: 0;
            }
          }
        }
      }
    }
    .checkoutSpei__openpay__buttom {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      > * {
        margin: 0 15px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .checkoutSpei {
    background-color: #ffffff;
    margin-right: 0px;
    padding-bottom: 50px;

    .checkoutSpei__instContainer {
      font-size: 14px;
      ol {
        text-align: center;
        li {
          margin-bottom: 10px;
        }
      }
    }
    .checkoutSpei__account {
      margin: 0px;
      .checkoutSpei__account__key {
        flex-direction: column-reverse;
        align-items: center;
        .checkoutSpei__account__notice {
          display: flex;
          justify-content: center;
        }
      }
      .checkoutSpei__title__main {
        margin: 20px auto;
        font-size: 14px;
        text-align: center;
      }
      .checkoutSpei__row {
        flex-direction: column;
        align-items: center;
        p {
          margin: 10px 0;
        }
        .checkoutSpei__title {
          width: auto;
          font-family: Montserrat-Medium;
          text-align: center;
        }

        .checkoutSpei__value {
          width: auto;
          font-family: Montserrat-Bold;
          font-size: 14px;
          color: #000000;
        }
        .checkoutSpei__account__clabe {
          display: flex;
          flex-direction: column;
          align-items: center;
          .checkoutSpei__clabe {
            margin-right: 0px;
          }
        }
      }
      hr {
        display: none;
      }
    }
    .checkoutSpei__openpay {
      .checkoutSpei__openpay__inst {
        .checkoutSpei__openpay__instContent {
          width: 100%;
          margin-top: 10px;
        }
      }
      .checkoutSpei__openpay__buttom {
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
