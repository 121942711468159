.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  .success__notice1 {
    color: var(--luxtak--color);
    font-family: Montserrat-Regular;
    font-size: 16px;
  }
  .success__notice2 {
    font-family: Montserrat-Regular;
    font-size: 12px;
    color: #878787;
    text-align: center;
  }
}

@media only screen and (max-width: 800px) {
  .success {
    background-color: #fff;
    padding: 20px 0;
    .success__notice1 {
      margin: 10px 0;
    }
    .success__notice2 {
      margin: 10px 12px;
    }
  }
}
