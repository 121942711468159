.CheckoutMultibanco {
  margin-right: 50px;
}

@media only screen and (max-width: 800px) {
  .CheckoutMultibanco {
    margin-right: 0;
    background-color: #ffffff;
    .table {
      padding: 5px;
    }
  }
}
