.checkoutBarcode {
  margin-right: 50px;
  .checkoutBarcode__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .checkoutBarcode__ins {
    background-color: #eefaea;
    padding: 5px 20px;
    font-family: Montserrat-Regular;
    font-size: 14px;
    .checkoutBarcode__howTo {
      font-family: Montserrat-Bold;
      font-size: 15px;
    }
    ol {
      padding-left: 20px;
    }
    .checkoutBarcode__reference {
      font-family: Montserrat-Bold;
      color: var(--luxtak--color);
    }
  }
}

@media only screen and (max-width: 800px) {
  .checkoutBarcode {
    background-color: #ffffff;
    margin-right: 0px;
    padding-bottom: 50px;
    .checkoutBarcode__title {
      flex-direction: column-reverse;
      > * {
        margin-top: 0;
      }
      img {
        margin-top: 10px;
      }
    }
    .checkoutBarcode__ins {
      ol {
        li {
          margin: 10px 0;
        }
      }
      .checkoutBarcode__howTo {
        text-align: center;
      }
    }
  }
}
