.saveButton__button {
  font-family: Montserrat-Regular;
  height: 45px;
  min-width: 150px;
  font-size: 15px;
  color: #ffffff;
  background-color: var(--luxtak--color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 5px 0;
  > * {
    margin: 0 3px;
  }
}
