.infoPromp {
  border-radius: 50%;
  font-size: 0;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.infoPromp:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.infoPromp:hover .infoPromp__icon {
  fill: white;
}
