.homeMobile,
.home__headerMobile {
  display: none;
}
.home {
  display: flex;
  justify-content: center;
  padding: 0 30px;
  .home__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 1200px;
    .home__left {
      margin: 30px 24px 0 0;
      box-sizing: border-box;
      width: 100%;
      .home__insert {
        border-left: dashed 1px var(--luxtak--color);
        padding-left: 30px;
        padding-bottom: 40px;
        position: relative;
        .MuiFormLabel-root.Mui-error,
        .MuiInputLabel-root {
          // font-family: Montserrat-Regular;
          font-size: 15px;
        }

        .home__steps {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #bcbdbd;
          color: white;
          border-radius: 10px;
          font-size: 15px;
          top: 0;
          left: -12px;
          height: 22px;
          width: 22px;
        }
        .home__insertContainer {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
        }
        .home__insertTitle {
          font-family: Montserrat-Bold;
          font-size: 13px;
          color: #0e2c0e;
          margin: 0 0 24px 0;
          padding-top: 2px;
        }

        .home__insert__inputContainer {
          position: relative;
          img {
            position: absolute;
            top: 12px;
            right: 10px;
          }
        }
      }
      .home__channelPromotion__notice {
        font-family: Montserrat-Medium;
        font-size: 13px;
        margin: 10px 5px 0;
        color: #f1a00b;
        a {
          color: #3341b1;
          text-decoration: none;
        }
      }
    }
    .home__right {
      width: 320px;
      background-color: #f1f4f8;
      padding: 0 30px 30px;
      margin: 20px 0;
      flex-shrink: 0;
      .home__time {
        display: flex;
        align-items: center;
        margin-top: 22px;
        margin-bottom: 10px;
      }
      .home__payDetail {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 100%;

        p {
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .home__payDetail__trxIdValueContainer {
          margin-bottom: 10px;
        }
        .home__payDetail__hide {
          font-family: DMSans-Medium;
          font-size: 14px;
          color: #1e86ff;
          cursor: pointer;
        }
        .home__payDetail__trxIdText {
          margin: 0;
        }
        hr {
          border-width: 0;
          height: 1px;
          background-color: rgba(145, 145, 147, 0.2);
          width: 100%;
        }

        .home__payDetail__button:active {
          background-color: var(--luxtak--color);
          transition: background-color 0.1s ease-in;
        }
        .home__payDetail__totalText {
          font-family: Montserrat-Medium;
          font-size: 15px;
          color: #0e2c0e;
        }
        .home__payDetail__totalValue {
          font-family: Montserrat-Regular;
          font-size: 20px;
          margin-left: 6px;
          color: #0e2c0e;
        }
        .home__payDetail__button {
          height: 50px;
          width: 100%;
          margin-top: 10px;
          margin-bottom: 34px;
          color: #ffffff;
          font-family: DMSans-Bold;
          font-size: 17px;
          border: none;
          border-radius: 4px;
          background-color: var(--luxtak--color);
          cursor: pointer;
        }
        .home__payDetail__checkboxContainer {
          display: flex;
          align-items: center;
          font-family: Montserrat-Regular;
          font-size: 12px;
          margin-bottom: 10px;
          .home__payDetail__privacyText1 {
            margin-left: 6px;
            color: #6a6f7a;
          }
          .home__payDetail__privacyText2 {
            color: #0376ff;
            cursor: pointer;
          }
        }
        .home__payDetail__return {
          margin: 10px 0 20px;
          display: flex;
          justify-content: center;
          font-family: Montserrat-Regular;
          font-size: 13px;
          span {
            color: var(--luxtak--color);
            text-decoration: underline;
          }
        }
        .home__payDetail__support {
          display: flex;
          flex-direction: column;
          font-family: Montserrat-Regular;
          color: #6a6f7a;
          font-size: 11px;
          .home__payDetail__support__row {
            display: flex;
            align-items: center;
            img {
              padding: 0 10px;
            }
            p {
              margin: 6px 0;
            }
          }
        }
      }
    }
  }
}

.home--fillTitle {
  font-family: Montserrat-Bold;
  font-size: 13px;
  color: #0e2c0e;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--luxtak--color) !important;
}
.home__payDetail--text {
  font-family: Montserrat-Regular;
  font-size: 13px;
  color: #878787;
}
.home__payDetail--value {
  font-family: Montserrat-Regular;
  font-size: 13px;
  color: #0e2c0e;
}
.home__payDetail--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.home__payDetail--floatRight {
  display: flex;
  justify-content: flex-end;
}
.home--column {
  display: flex;
  flex-direction: column;
}

.home--row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  > * {
    width: 49% !important;
  }
}

.home--grow {
  flex-grow: 1;
}
.home--error {
  color: #e22d2df7 !important;
  margin: 5px 0 !important;
  font-family: Montserrat-Regular;
  font-size: 13px;
}
.puff__loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(188, 189, 189, 0.2);

  .loader {
    position: relative;
    height: 200px;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.iframePopUp {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(188, 189, 189, 0.34);
  .iframePopUp__window {
    position: relative;
    top: 50%;
    left: 50%;
    height: 500px;
    width: 600px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
  }
}

.home__notice {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(188, 189, 189, 0.34);
  .home__notice__warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 20%;
    left: 50%;
    transform: translate(-50%);
    padding: 20px;
    height: 300px;
    width: 400px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #dee8f2;

    .home__notice__warningClose {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    .home__notice__warningText {
      font-family: Montserrat-Bold;
      color: var(--luxtak--color);
      font-size: 16px;
      margin: 25px 0;
      text-align: center;
    }
    .home__notice__warningButton {
      width: 120px;
      height: 44px;
      background: rgba(20, 117, 223, 0);
      border: 1px solid var(--luxtak--color);
      border-radius: 20px;
      font-family: Montserrat-Regular;
      font-size: 16px;
      color: var(--luxtak--color);
      cursor: pointer;
    }
  }
}
@media only screen and (max-width: 800px) {
  .home,
  .home__header {
    display: none;
  }
  .home__notice {
    .home__notice__warning {
      width: 90%;
      box-sizing: border-box;
    }
  }
  .arrow__0 {
    transform: rotate(0deg);
    transition: transform 0.2s linear;
  }
  .arrow__90 {
    transform: rotate(90deg);
    transition: transform 0.2s linear;
  }
  .arrow__180 {
    transform: rotate(180deg);
    transition: transform 0.2s linear;
  }
  .iframePopUp {
    .iframePopUp__window {
      position: relative;
      top: 50%;
      left: 50%;
      height: 60%;
      width: 90%;
      transform: translate(-50%, -50%);
      background-color: #ffffff;
    }
  }
  .homeMobile {
    box-sizing: border-box;
    display: block;
    background-color: #f1f4f8;
    padding: 0 15px;
    width: 100%;
    min-height: 100%;
    padding-bottom: 260px;
    padding-top: 75px;

    .homeMobile__channel {
      background-color: #ffffff;
      border-radius: 12px;
      padding: 10px 12px;
      margin-bottom: 20px;
      .homeMobile__channel__top {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .homeMobile__channelName {
          font-family: Montserrat-Bold;
          font-size: 14px;
          color: #000000;
          width: calc(100% - 105px);
          overflow-x: auto;
        }
        .homeMobile__channelRight {
          display: flex;
          align-items: center;
          .homeMobile__channelRight__icon {
            width: 60px;
            height: 30px;
            object-fit: scale-down;
            margin-left: 5px;
            padding: 5px;
            //border: 1px dashed rgba(0, 0, 0, 0.23);
          }
          .homeMobile__channelRight__arrow {
            margin-left: 10px;
          }
        }
      }
      .homeMobile__channel__bottom {
        .homeMobile__channel__bottom__trxIdText {
        }
        .homeMobile__channel__bottom__trxIdValue {
        }
      }
    }
    .homeMobile__paymentMethod {
      font-family: Montserrat-Regular;
      font-size: 16px;
      color: #000000;
      padding: 0 10px;
    }

    .homeMobile__footer {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      padding: 5px 30px;
      padding-top: 0;
      background-color: #ffffff;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
      z-index: 300;
      border-radius: 20px 20px 0 0;
      .homeMobile__footer__sort {
        margin: 0 auto;
        width: 24px;
        padding: 0 100px;
      }
      p {
        margin: 5px 0;
      }
      .homeMobile__footer__time {
        display: flex;
        align-items: center;
        margin: 5px 0;
        > * {
          margin-left: 3px;
          margin-right: 3px;
        }
      }
      .homeMobile__footer__order {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0;
        font-family: Montserrat-Regular;
        color: #000000;
        font-size: 14px;
      }
      .homeMobile__footer__totalText {
        font-family: Montserrat-Bold;
      }
      .homeMobile__footer__totalValue {
        font-family: Montserrat-Bold;
        color: var(--luxtak--color);
      }
      .homeMobile__footer__payButton {
        background-color: var(--luxtak--color);
        border: none;
        border-radius: 4px;
        height: 50px;
        margin: 5px 0;
        color: #ffffff;
        font-family: Montserrat-Bold;
        font-size: 20px;
      }
      .homeMobile__footer__payButton:active {
        background-color: #048367;
        transition: background-color 0.1s ease-in;
      }
    }
  }
  .home--row {
    display: block;
    margin-bottom: 0;
    > * {
      width: 100% !important;
      margin-bottom: 20px !important;
    }
  }
}

.MuiRadio-colorSecondary.Mui-checked {
  color: var(--luxtak--color) !important;
}

@media only screen and (max-width: 800px) and (max-height: 500px) {
  .homeMobile {
    padding-bottom: 0px;
    padding-top: 80px;
    .homeMobile__footer {
      position: static;
      margin-top: 10px;
    }
  }
}
