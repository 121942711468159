.CheckoutFawryCash {
  margin-right: 50px;
  font-family: Montserrat-Medium;
  .content {
    font-size: 14px;
    font-weight: 500;
    padding: 0 5px;
  }
}

@media only screen and (max-width: 800px) {
  .CheckoutFawryCash {
    margin-right: 0;
    background-color: #ffffff;
    .table {
      padding: 5px;
    }
  }
}
