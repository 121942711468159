.checkoutWallet {
  .checkoutWallet--mobile {
    display: none;
  }
  border: 2px solid #f1f4f8;
  height: calc(100% - 4px);
  margin-right: 50px;
  .checkoutWallet__title {
    font-family: Montserrat-Bold;
    font-size: 20px;
    color: #0e2c0e;
    background-color: #eefaea;
    border-bottom: 2px solid #f1f4f8;
    margin: 0;
    padding: 5px 50px;
  }
  .checkoutWallet__content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 40px;
    .checkoutWallet__content__qrContent {
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .checkoutWallet__content__total {
        font-family: Montserrat-Bold;
        text-align: center;
        opacity: 0.65;
        margin-bottom: 5px;
      }
      .checkoutWallet__content__qrContainer {
        position: relative;
        .checkoutWallet__content__qr {
          border: 2px solid #eaeaea;
          padding: 10px;
          border-radius: 8px;
        }
        .checkoutWallet__content__qrNotice {
          font-family: Montserrat-Regular;
          font-size: 12px;
          background-color: #ffffff;
          opacity: 0.8;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 110px;
          border-radius: 4px;
          text-align: center;
        }
      }

      .checkoutWallet__content__countDown {
        font-family: Montserrat-Regular;
        font-size: 13px;
        margin: 5px 0;
      }
    }
    .checkoutWallet__content__inst {
      padding-left: 20px;
      .checkoutWallet__content__inst__titleContainer {
        .checkoutWallet__content__inst__title {
          font-family: Montserrat-Bold;
          font-size: 16px;
          color: #0e2c0e;
        }
      }

      .checkoutWallet__content__inst__howToPay {
        p {
          font-family: Montserrat-Regular;
          font-size: 14px;
          color: #7f8fa4;
        }
        .checkoutWallet__content__inst__howToPay__title {
          font-family: Montserrat-Bold;
          font-size: 14px;
          color: #616161;
        }
      }
    }
  }
  .checkoutWallet__buttonContainer {
    display: none;
  }
  .checkoutWallet__alipayPartner {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat-Regular;
    font-size: 14px;
    color: #7f8fa4;
    margin: 40px 0 20px;
    span {
      line-height: 1.3;
    }
  }
}

@media only screen and (max-width: 800px) {
  .checkoutWallet {
    .checkoutWallet--mobile {
      display: block;
    }
    .checkoutWallet--pc {
      display: none;
    }
    background-color: #ffffff;
    margin-right: 0;
    .checkoutWallet__title {
      text-align: center;
      padding: 5px;
    }
    .checkoutWallet__content {
      flex-wrap: wrap-reverse;
      padding: 10px 30px;
      // .checkoutWallet__content__qrContent {
      //   display: none;
      // }
      .checkoutWallet__content__inst {
        padding-left: 0;
        .checkoutWallet__content__inst__titleContainer {
          text-align: center;
        }
      }
    }
    .checkoutWallet__buttonContainer {
      display: flex;
      justify-content: center;
      margin: 10px 0 20px;
      button {
        font-family: Montserrat-Regular;
        font-size: 15px;
        color: var(--luxtak--color);
        background-color: #ffffff;
        border: 2px solid var(--luxtak--color);
        border-radius: 4px;
        height: 45px;
        cursor: pointer;
      }
    }
  }
}
