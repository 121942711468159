.checkoutPix {
  margin-right: 50px;

  .checkoutPix__instContainer {
    background-color: var(--luxtak--color--light);
    padding: 20px;
    font-size: 15px;
    > p {
      margin: 0;
      font-family: Montserrat-Medium;
    }
  }
  .checkoutPix__content {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > * {
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .checkoutPix {
    background-color: #ffffff;
    margin-right: 0px;
    padding-bottom: 50px;
    .checkoutPix__instContainer {
      padding: 10px;
      margin-right: 0px;
      font-size: 15px;
      > p {
        font-family: Montserrat-Medium;
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        margin: 10px 0;
      }
    }
    .checkoutPix__content {
      margin-top: 30px;
    }
  }
}
