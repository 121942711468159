.checkoutOxxo {
  margin-right: 50px;

  .checkoutOxxo__instContainer {
    background-color: #eefaea;
    padding: 20px;
    font-size: 15px;
    > p {
      margin: 0;
      font-family: Montserrat-Regular;
    }
    .checkoutOxxo__instContainer__number {
      font-weight: 800;
      color: #3341b1;
      margin-left: 5px;
    }
  }

  .checkoutOxxo__saveAndMedia {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 10px;
  }

  .checkoutOxxo__fileContainer {
    margin-top: 35px;
    display: flex;
    justify-content: center;
    img {
      display: none;
    }
  }
}

@media only screen and (max-width: 800px) {
  .checkoutOxxo {
    background-color: #ffffff;
    margin-right: 0px;
    padding-bottom: 40px;
    .checkoutOxxo__saveAndMedia {
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
    }
    .checkoutOxxo__instContainer {
      padding: 10px;
      margin-right: 0px;
      font-size: 15px;
      line-height: 1.5;
      > p {
        font-family: Montserrat-Regular;
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        margin: 10px 0;
      }
    }

    .checkoutOxxo__shareContainer {
      justify-content: center;
    }
    .checkoutOxxo__fileContainer {
      canvas {
        width: 100% !important;
      }
      > * {
        width: 100%;
      }
      embed {
        display: none;
      }
      img {
        display: block;
      }
      .checkoutOxxo__fileContainer__img {
        width: 100%;
      }
    }
  }
}
