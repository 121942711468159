.paymentCodeMobile {
  display: none;
}
.paymentCode_container {
  width: 100%;
  box-sizing: border-box;
  .paymentCode {
    width: 100%;
    .paymentCode__paymentCodeText {
      font-family: Montserrat-Regular;
      font-size: 13px;
      color: #878787;
      margin: 0 0 5px 0 !important;
    }
    .paymentCode__paymentCodeContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .paymentCode__paymentCodeValue {
        font-family: Montserrat-Regular;
        font-size: 15px;
        color: #0e2c0e;
        margin: 0;
      }
      .paymentCode__copyButton {
        font-family: Montserrat-Regular;
        font-size: 15px;
        color: #ffffff;
        background-color: var(--luxtak--color);
        border: none;
        border-radius: 4px;
        height: 36px;
        width: 90px;
        cursor: pointer;
      }
      .paymentCode__copyButton:active {
        background-color: #048367;
        transition: background-color 0.2s ease-in;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .paymentCode {
    display: none;
  }
  .paymentCodeMobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    color: #0c3666;
    .paymentCodeMobile__text {
      font-family: Montserrat-Regular;
      font-size: 13px;
      margin: 5px 0px;
    }
    .paymentCodeMobile__value {
      font-family: Montserrat-Bold;
      font-size: 14px;
      margin: 5px 0px;
    }
    .paymentCodeMobile__copyButton {
      font-family: Montserrat-Regular;
      font-size: 15px;
      color: #ffffff;
      background-color: var(--luxtak--color);
      border: none;
      border-radius: 4px;
      height: 36px;
      width: 90px;
      cursor: pointer;
    }
  }
}
